import LAYOUT_CONST from './constant';
import { ConfigProps } from './types/config';

// basename: only at build time to set, and Don't add '/' at
// end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
export const BASE_PATH = '/';

export const HOME_PATH = '/';
export const HORIZONTAL_MAX_ITEM = 6;

const config: ConfigProps = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: '"Roboto", sans-serif',
  borderRadius: 8,
  outlinedFilled: true,
  navType: 'light', // light, dark
  presetColor: 'runthevuTheme', // default, theme1, theme2, theme3, theme4, theme5, theme6, runthevuTheme
  locale: 'en', // 'en' - English, 'fr' - French
  rtlLayout: false,
  container: false,
};

export default config;
