import './App.css';
// import { Routes, Route } from "react-router-dom";
import Routes from 'routes';
import NavigationScroll from 'layout/NavigationScroll';
import ThemeCustomization from 'themes';
import Locales from 'ui-component/Locales';
import RTLLayout from 'ui-component/RTLLayout';
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import Snackbar from 'ui-component/extended/Snackbar';

function App() {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <NavigationScroll>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </NavigationScroll>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
}

export default App;
