import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import loadable from 'ui-component/Loadable';

const PagesLanding = loadable(
  // lazy(() => import('pages/landing-page/LandingPage')),
  lazy(() => import('pages/landing-page/ComingSoon')),
);
const ErrorPage = loadable(lazy(() => import('maintenance/Error')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    { path: '/', element: <PagesLanding /> },
    { path: '*', element: <ErrorPage /> },
    MainRoutes,
  ]);
}
