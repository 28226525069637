/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';
import { getAnalytics } from 'firebase/analytics';

// types
import { FirebaseContextType, InitialLoginContextProps } from 'types/auth';

// firebase initialize
if (!firebase.apps.length) {
  const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
  getAnalytics(app);
}

// const
const initialState: InitialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                id: user.uid,
                email: user.email || 'Email not provided',
                name: user.displayName || 'User name not found',
              },
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      }),
    [dispatch],
  );

  const firebaseResetPassword = (code: string, newPassword: string) =>
    firebase.auth().confirmPasswordReset(code, newPassword);

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseResetPassword,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContext;
